import { useState, useEffect, useMemo } from "react";

// @mui core components
import Card from "@mui/material/Card";
import SuiEditor from "components/SuiEditor";
// Soft UI Dashboard PRO React components
import { SuiBoxTs } from "components/SuiBox";
import { SuiTypographyTs } from "components/SuiTypography";
import { SuiSelectTs } from "components/SuiSelect";
// Data
import { SuiButtonTs } from "components/SuiButton";
import { useParams } from "react-router-dom";
import SuiDatePicker from "components/SuiDatePicker";
import { useGetCommunities } from "api/services/communities/queries/GetCommunities";
import { Box, Checkbox, CircularProgress } from "@mui/material";
import { GetTicketAssignmentMemebers } from "api/services/tickets/queries";
import useCreateTicket from "api/services/tickets/mutations/CreateTicket";
import SuiInputTs from "components/SuiInput/SuiInputTs";
import { TicketModel } from "api/services/tickets/models";
import { getPermission } from "utils";
import useEventEmitter from "lib/events/useEventEmitter";
import { EVENTS } from "lib/events/events";
import { setNotification } from "redux/actions/communities";
import { useDispatch } from "react-redux";
import { useAuthenticationStore } from "stores/authentication";

const PRIORTY_OPTIONS = [
  { label: "Baja", value: "baja" },
  { label: "Media", value: "media" },
  { label: "Alta", value: "alta" },
  { label: "Crítica", value: "crítica" },
];
const AddTicketForm = (props) => {
  const { handleClose } = props;
  const dispatch = useDispatch();
  const newTicketCreated = useEventEmitter(EVENTS.NEW_TICKET);
  const { community: communitySlug } = props;
  const { slug: paramCommunitySlug } = useParams();
  const [title, settitle] = useState<string>("");
  const [comment, setcomment] = useState<string>("");
  const [complexTimeline, setcomplexTimeline] = useState<boolean>(false);
  const [priority, setpriority] = useState<string>("baja");
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [selectedAssignee, setSelectedAssignee] = useState([]);
  const [selectedResponsible, setSelectedResponsible] = useState();
  const [isAfterSale, setisAfterSale] = useState(false);
  const [isRecurrent, setIsRecurrent] = useState(false);
  const [periodicity, setPeriodicity] = useState("diario");
  const [isLoading, setIsLoading] = useState(false);

  const [selectedCommunity, setSelectedCommunity] = useState<string>(
    communitySlug ||
      paramCommunitySlug ||
      (getPermission(["comite", "mayordomo", "conserje"])
        ? useAuthenticationStore.getState().userProfile.communities[0]?.slug
        : null) ||
      null
  );


  const createTicket = useCreateTicket({
    communitySlug: selectedCommunity,
    isRecurrent: isRecurrent,
  });
  const handleSubmit = (e) => {
    if(title.length < 1){
      dispatch(
        setNotification({
          status: 'error',
          message: 'La tarea debe tener un título',
          title: 'Tarea no creada',
        })
      );
      return;
    }
    if(!selectedCommunity){
      dispatch(
        setNotification({
          status: 'error',
          message: 'La tarea debe tener una comunidad',
          title: 'Tarea no creada',
        })
      );
      return;
    }
    const form = {
      title,
      comment,
      service_ticket: complexTimeline,
      priority,
      is_after_sale: isAfterSale,
      pre_assignees: selectedAssignee.map((assignee) => assignee.value),
      maintenance_uuid: props.maintenanceUuid || undefined,
      area_uuid: props.areaUuid || undefined,
      report: props.visitReport || undefined,
      responsible: selectedResponsible || null,
      due_date: undefined,
      period: undefined,
      start_date: undefined,
      end_date: undefined,
    };

    if (
      props.actionPlanView === "weekly" ||
      props.actionPlanView === "monthly"
    ) {
      const hoy = new Date();
      const masDias = props.actionPlanView === "weekly" ? 7 : 30;
      const masDiasDate = new Date(hoy);
      masDiasDate.setDate(hoy.getDate() + masDias);
      form.due_date = masDiasDate.toISOString().split("T")[0];
    }

    if (isRecurrent) {
      form.period = periodicity;
      form.start_date = selectedStartDate;
      form.end_date = selectedEndDate;
    }

    const community =
      props.community ||
      selectedCommunity ||
      paramCommunitySlug ||
      (props.maintenance && props.maintenance.community.slug);

    if (community) {
      setIsLoading(true);
      createTicket.mutate(form, {
        onSuccess: (data: TicketModel) => {
          setIsLoading(false);
          if (!isRecurrent) newTicketCreated.emit(data.uuid);
          handleClose?.();
        },
        onError: () => {
          setIsLoading(false);
        },
      });
    }
  };

  useEffect(() => {
    props.scroll();
  }, [isRecurrent]);

  const { dataAsOptions: communityOptions } = useGetCommunities(false);
  const {
    selectOptions: membersOptions,
    defaultSelected: autoAssignment,
    data,
  } = GetTicketAssignmentMemebers({
    communitySlug: selectedCommunity,
  });

  // Modificamos el useEffect para que solo se ejecute cuando no hay asignados seleccionados
  useEffect(() => {
    if (
      autoAssignment &&
      autoAssignment.length > 0 &&
      selectedAssignee.length === 0
    ) {
      setSelectedAssignee(autoAssignment);
    }
  }, [autoAssignment]);

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <Box p={3}>
        <SuiBoxTs component="form" pb={3}>
          <Box>
            <SuiTypographyTs variant="h5">Crear Tarea</SuiTypographyTs>
          </Box>
          <Box>
            <SuiTypographyTs variant="button">Título</SuiTypographyTs>
            <SuiInputTs
              value={title}
              onChange={(e) => settitle(e.target.value)}
              size="small"
            />
          </Box>
          <Box>
            <SuiTypographyTs variant="button">Prioridad</SuiTypographyTs>
            <SuiSelectTs
              size="small"
              option={priority}
              onChange={(e) => setpriority(e.value)}
              placeholder="Prioridad"
              options={PRIORTY_OPTIONS}
              value={priority}
            />
          </Box>
          <Box>
            {!paramCommunitySlug &&
              !props.maintenance &&
              !props.community &&
              !getPermission(["comite", "conserje", "mayordomo"]) && (
                <>
                  <SuiBoxTs
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SuiTypographyTs
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Comunidad
                    </SuiTypographyTs>
                  </SuiBoxTs>
                  <SuiSelectTs
                    size="small"
                    onChange={(e) => setSelectedCommunity(e.value)}
                    placeholder="Comunidad"
                    options={[...communityOptions]}
                    value={selectedCommunity}
                  />
                </>
              )}
          </Box>
          <Box>
            <SuiTypographyTs
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Responsable
            </SuiTypographyTs>
            <SuiSelectTs
              size="small"
              onChange={(e) => setSelectedResponsible(e.value)}
              placeholder="asignados"
              options={membersOptions}
              value={selectedResponsible}
            />
          </Box>
          <Box>
            <SuiTypographyTs
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Asignados
            </SuiTypographyTs>
            <SuiSelectTs
              size="small"
              isMulti
              onChange={(e) => setSelectedAssignee(e)}
              placeholder="asignados"
              options={membersOptions}
              value={selectedAssignee}
            />
          </Box>
          <Box mt={2}>
            <Box>
              <SuiTypographyTs
                mr={1}
                pt={1}
                variant="button"
                style={{ float: "left" }}
              >
                ¿Requiere cotización de servicios?
              </SuiTypographyTs>
              <Checkbox
                onChange={() => setcomplexTimeline(!complexTimeline)}
                style={{ border: "2px solid black" }}
              />
            </Box>
            <Box>
              <SuiTypographyTs
                mr={1}
                pt={1}
                variant="button"
                style={{ float: "left" }}
              >
                ¿Es postventa?
              </SuiTypographyTs>
              <Checkbox
                onChange={() => {
                  setisAfterSale(!isAfterSale);
                }}
                style={{ border: "2px solid black" }}
              />
            </Box>
            <Box>
              <SuiTypographyTs
                mr={1}
                pt={1}
                variant="button"
                style={{ float: "left" }}
              >
                ¿Es recurrente?
              </SuiTypographyTs>
              <Checkbox
                onChange={() => {
                  setIsRecurrent(!isRecurrent);
                }}
                style={{ border: "2px solid black" }}
              />
              <Box>
                {isRecurrent && (
                  <>
                    <Box>
                      <SuiTypographyTs pt={1} variant="button">
                        Periodicidad
                      </SuiTypographyTs>
                      <SuiSelectTs
                        size="small"
                        value={periodicity}
                        placeholder="Periodicidad"
                        options={[
                          { label: "Diario", value: "diario" },
                          { label: "Semanal", value: "semanal" },
                          { label: "Quincenal", value: "quincenal" },
                          { label: "Mensual", value: "mensual" },
                          { label: "Bimestral", value: "bimestral" },
                          { label: "Trimestral", value: "trimestral" },
                          { label: "Semestral", value: "semestral" },
                          { label: "Anual", value: "anual" },
                        ]}
                        onChange={(e) => setPeriodicity(e.value)}
                      />
                    </Box>
                    <Box>
                      <SuiTypographyTs
                        pt={1}
                        variant="button"
                        style={{ float: "left" }}
                      >
                        Fecha de inicio
                      </SuiTypographyTs>
                      <SuiDatePicker
                        style={{ zIndex: 1000000 }}
                        onChange={(e) =>
                          setSelectedStartDate(e[0].toISOString().split("T")[0])
                        }
                      />
                    </Box>
                    <Box>
                      <SuiTypographyTs
                        pt={1}
                        variant="button"
                        style={{ float: "left" }}
                      >
                        Fecha de término
                      </SuiTypographyTs>
                      <SuiDatePicker
                        style={{ zIndex: 1000000 }}
                        onChange={(e) =>
                          setSelectedEndDate(e[0].toISOString().split("T")[0])
                        }
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>

          <Box mt={2}>
            <SuiTypographyTs variant="button">Descripción</SuiTypographyTs>

            <SuiEditor
              label="Comentario"
              value={comment}
              placeholder="Descripción"
              onChange={(e) => setcomment(e)}
              multiline
              rows={5}
            />
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
            <SuiButtonTs
              variant="gradient"
              color="success"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={12} /> : "Guardar"}
            </SuiButtonTs>
          </Box>
        </SuiBoxTs>
      </Box>
    </Card>
  );
};

export default AddTicketForm;
