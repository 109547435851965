import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, Typography } from "@mui/material";
import { MaintenanceModel } from "api/services/maintenances/models";

import DatePickerControlled from "components/forms/controlled/DatePickerControlled";
import { SuiButtonTs } from "components/SuiButton";
import React from "react";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { scheme } from "./form.helpers";
import useCreateMaintenanceRegenerateVisit from "api/services/maintenances/mutations/createMaintenanceRecurrentVisits";
import useEventEmitter from "lib/events/useEventEmitter";

interface Props {
  maintenance: MaintenanceModel;
}

export const EVENT_VISIT_REGENERATE_CREATED = "EVENT_VISIT_REGENERATE_CREATED";

const CreateMaintenanceRegenerateVisitForm: React.FC<Props> = ({
  maintenance,
}) => {
  const eventVisitCreated = useEventEmitter(EVENT_VISIT_REGENERATE_CREATED);
  const {
    control,
    watch,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm({
    resolver: yupResolver(scheme),
  });
  const { mutate: createRegenerateVisit } =
    useCreateMaintenanceRegenerateVisit();
  const onSubmit = handleSubmit((data) => {
    createRegenerateVisit(
      {
        start_date:
          data.start_date[0] && format(data.start_date[0], "yyyy-MM-dd"),
        end_date: data.end_date[0]
          ? format(data.end_date[0], "yyyy-MM-dd")
          : null,
        maintenanceUUID: maintenance.uuid,
      },
      {
        onSuccess: (data) => {
          eventVisitCreated.emit(data);
        },
      }
    );
  });
  return (
    <form onSubmit={onSubmit}>
      <Typography>Agregar Visitas Preventivas</Typography>
      <Stack spacing={1}>
        <DatePickerControlled
          control={control}
          label="Fecha de inicio"
          name="start_date"
          options={{
            minDate: new Date(new Date().getFullYear(), 0, 1),
            enableTime: false,
          }}
        />
        <DatePickerControlled
          control={control}
          label="Fecha de finalización"
          name="end_date"
          options={{
            minDate:
              watch("start_date")?.[0] && new Date(watch("start_date")[0]),
            enableTime: false,
          }}
        />

        <Box mt={2} display={"flex"} justifyContent={"flex-end"}>
          <SuiButtonTs
            disabled={!isDirty || !isValid}
            size="small"
            color="success"
            variant="gradient"
            type="submit"
          >
            Guardar
          </SuiButtonTs>
        </Box>
      </Stack>
    </form>
  );
};

export default CreateMaintenanceRegenerateVisitForm;
