/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { createProviderByManagement } from "redux/actions/providers";
// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import { useDispatch, useSelector } from "react-redux";
// NewProduct page components
import GeneralInfo from "./components/GeneralInfo";
import BankAccountInfo from "./components/BankAccountInfo";

import { validate, clean, format, getCheckDigit } from "rut.js";
import { validadorEmail } from "utils";
import DataTable from "examples/Tables/DataTable";
import SuiTypography from "components/SuiTypography";
import FormField from "./components/FormField";
import { useLocation, useParams } from "react-router-dom";
import { createContact } from "redux/actions/providers";
import { getProviderByUuid } from "redux/actions/providers";

const unitsTableData = (contacts) => {
  let columns = [
    { Header: "Nombre", accessor: "name" },
    { Header: "Email", accessor: "email" },
    { Header: "Phone", accessor: "phone" },
  ];

  if (Array.isArray(contacts)) {
    let rows = contacts.map((contact, i) => {
      return {
        name: contact.name,
        email: contact.email,
        phone: contact.phone,
      };
    });
    return { columns, rows };
  }

  return { columns, rows: [] };
};

function Form(props) {
  const dispatch = useDispatch();
  const { providerUuid } = useParams();
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [name, setname] = useState("");
  const [contacts, setcontacts] = useState(props.contacts || []);
  const createContactResponse = useSelector((state) => state.providers.createContact);
  const [dataTable, setdataTable] = useState(unitsTableData(contacts));
  const { state } = useLocation();

  useEffect(() => {
    if (createContactResponse) {
      dispatch(getProviderByUuid(providerUuid));
    }
  }, [createContactResponse]);

  useEffect(() => {
    setdataTable(unitsTableData(props.contacts));
  }, [props.contacts]);

  return (
    <SuiBox>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={12}>
          <Card sx={{ overflow: "visible" }}>
            <SuiTypography component="h6">Contactos</SuiTypography>
            <Grid container spacing={3} sx={{ padding: 3 }}>
              <Grid item xs={12} sm={4}>
                <FormField
                  label="Nombre"
                  placeholder="Nombre"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  label="Email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  label="Teléfono"
                  placeholder="Teléfono"
                  value={phone}
                  onChange={(e) => setphone(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SuiButton
                  variant="gradient"
                  color="success"
                  fullWidth
                  onClick={() => {
                    let form = {
                      name: name,
                      email: email,
                      phone: phone,
                    };
                    dispatch(createContact(providerUuid, form));
                  }}
                >
                  Agregar
                </SuiButton>
              </Grid>
            </Grid>
            <DataTable table={dataTable} />
          </Card>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default Form;
