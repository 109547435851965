import { useQuery } from "@tanstack/react-query";

import { UserModel } from "../models";
import apiClient from "api/config/axios";

export const GET_PROFILE_KEY = "get_profile_key";

const useGetAuthUserProfile = () => {
  return useQuery({
    retry: false,
    enabled: false,
    queryKey: [GET_PROFILE_KEY],
    queryFn: async () => {
      const url = "/users/profile/";
      const profile = await apiClient
        .get<UserModel>(url)
        .then((res) => res.data);
      localStorage.setItem("profile", JSON.stringify(profile));
      return profile;
    },
  });
};

export default useGetAuthUserProfile;
